export const getSeoMeta = (
  ogTitle: string,
  ogDescription?: string,
  ogUrl?: string
) => {
  return {
    ogTitle: ogTitle,
    ogDescription: ogDescription ?? getOGDescription(),
    ogUrl: ogUrl ?? (getConfig().baseUrl + useRoute().path)
  }
}

export const getCanonicalLink = () => {
  return {
    rel: 'canonical',
    href: getConfig().baseUrl + useRoute().path
  }
}
